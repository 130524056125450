import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import CSS for carousel

import achievement1 from "../assets/Images/achievement1.png";
import achievement2 from "../assets/Images/achievement2.png";
import achievement3 from "../assets/Images/achievement3.png";
import achievement4 from "../assets/Images/achievement4.png";
import achievement5 from "../assets/Images/achievement5.png";

const ImageCarousel = () => {
  return (
    <div className="w-150 s:w-2/3 mx-auto">
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
        stopOnHover={true}
        dynamicHeight={true}
        className='s:hidden'
      >
        <div>
          <img src={achievement1} alt="Slide 1" className="w-100"/>
        </div>
        <div>
          <img src={achievement2} alt="Slide 2" className="w-100"/>
        </div>
        <div>
          <img src={achievement3} alt="Slide 3" className="w-100"/>
        </div>
        <div>
          <img src={achievement4} alt="Slide 4" className="w-100"/>
        </div>
        <div>
          <img src={achievement5} alt="Slide 5" className="w-100"/>
        </div>
      </Carousel>
    </div>
  );
};

export default ImageCarousel;