// src/components/AboutMe.js
import React from 'react';
import ProfileLink from '../Elements/ProfileLinks';
import { GITHUB_LINK, LINKED_IN_LINK, RESUME_LINK } from '../utils/constants';

const AboutMe = () => {
  return (
    <section className="bg-black text-white">
      <h1 className="text-5xl font-bold mb-4">So who am I?</h1>
      <p className="text-lg mb-6">I’m Ravinder, a Software Developer currently working as an associate technical lead at <a className="text-blue-600" href='https://www.linkedin.com/company/paxcom-india/' target='_blank'>Paxcom India (P) Ltd - A Paymentus Company</a> with a passion for building robust, scalable & beautiful web products.</p>
      <p className="text-md mb-8 max-w-2xl">
        With over 6+ years of experience, I have developed applications that serve top of the line organisations. I specialize in creating efficient and scalable solutions using the latest web technologies.
      </p>

      <div className="grid grid-cols-3 gap-4 space-x-4 mb-12">
        <ProfileLink onClick={() => window.open(GITHUB_LINK, '_blank')}>
          <div className="text-4xl p-4 inline-flex justify-center items-center">
            <svg className="h-8 w-8 text-neutral-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" /></svg>
          </div>
          <p className="text-m">Check some of my side-projects.</p>
        </ProfileLink>
        <ProfileLink onClick={() => window.open(LINKED_IN_LINK, '_blank')}>
          <div className="text-4xl p-4 inline-flex justify-center items-center hover:z-100">
            <svg className="h-8 w-8 text-neutral-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />  <rect x="2" y="9" width="4" height="12" />  <circle cx="4" cy="4" r="2" /></svg>
          </div>
          <p className="text-m">Connect with me on LinkedIn.</p>
        </ProfileLink>
        <ProfileLink onClick={()=> window.open(RESUME_LINK, '_blank')}>
          <div className="text-4xl p-4 inline-flex justify-center items-center hover:z-100">
            <svg className="h-8 w-8 text-neutral-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />  <polyline points="7 10 12 15 17 10" />  <line x1="12" y1="15" x2="12" y2="3" /></svg>
          </div>
          <p className="text-m">Download my resume</p>
        </ProfileLink>
      </div> 
    </section>
  );
};

export default AboutMe;
