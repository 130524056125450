// src/components/Sidebar.js
import React from 'react';
import SidebarElement from '../Elements/SidebarElement';

const Sidebar = ({activeTab = 0, onClick = (clickedTab = 0) => console.log(clickedTab)}) => {

  const skilTabIsActive = activeTab == 0;
  const workHistoryTabIsActive = activeTab == 1;
  const technologiesTabIsActive = activeTab == 2;
  const achievementsTabIsActive = activeTab == 3;
  const reccomendationsTabIsActive = activeTab == 4;

  return (
    <aside className="text-gray-400 shadow-lg w-96">
      <ul className="space-y-6 bg-gray-900 p-6 rounded-lg">
        <li>  
          <SidebarElement
            headerText='About me'
            bodyText='My professional bio with connection links.'
            isActive={skilTabIsActive}
            onClick={() => onClick(0)}
          />
        </li>
        <li>
          <SidebarElement
            headerText='Work History'
            bodyText='A birds eye view of some of my highlighted projects as a software developer with the list of skills used.'
            isActive={workHistoryTabIsActive}
            onClick={() => onClick(1)}
          />
        </li>
        <li>
          <SidebarElement
            headerText='Technologies'
            bodyText='List of technologies I have worked on during my professional journey with the knowledge proficiency.'
            isActive={technologiesTabIsActive}
            onClick={() => onClick(2)}
          />
        </li>
        <li>
          <SidebarElement
            headerText='Achievements'
            bodyText='Some of my achievements and appreciations I recieved while performing my professional duties as a software developer.'
            isActive={achievementsTabIsActive}
            onClick={() => onClick(3)}
          />
        </li>
        <li>
          <SidebarElement
            headerText='Recommendations'
            bodyText='Trusted and respected by peers, managers, and clients. Here’s what others say about working with me.'
            isActive={reccomendationsTabIsActive}
            onClick={() => onClick(4)}
          />
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;