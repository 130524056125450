import React, {useState} from 'react';

import Header from './Components/Header';
import Sidebar from './Components/Sidebar';

import AboutMe from './Components/AboutMe';
import WorkHistory from './Components/WorkHistory';
import Technologies from './Components/Technologies';
import Achievements from './Components/Achievements';
import LinkedInRecommendations from './Components/LinkedInRecommendations';

import './App.css';


function App() {
  
  const [activeTab, setActiveTab] = useState(0);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const onSideBarClick = (tabIndex) => {
    setActiveTab(tabIndex);
    setIsSidebarOpen(false); // Close the sidebar after selecting a tab
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="bg-black text-white min-h-screen w-full">
      <Header />
      
      {/* Toggle button for mobile view */}
      <button 
        className="md:hidden p-4 bg-gray-800 text-white fixed top-4 left-4 z-50" 
        onClick={toggleSidebar}
      >
        <svg 
          className="h-6 w-6" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="2" 
            d="M4 6h16M4 12h16M4 18h16" 
          />
        </svg>
      </button>

      <main className="grid grid-cols-1 md:grid-cols-3 mx-auto px-4 md:px-12 py-8">
        <div className="md:col-span-2 fade-in py-6 px-4 md:px-8 w-full">
          {activeTab === 0 && <AboutMe />}
          {activeTab === 1 && <WorkHistory />}
          {activeTab === 2 && <Technologies />}
          {activeTab === 3 && <Achievements />}
          {activeTab === 4 && <LinkedInRecommendations />}
        </div>

        {/* Sidebar: behaves like a drawer on mobile */}
        <div
          className={`fixed top-0 left-0 h-full transition-transform transform ${
            isSidebarOpen ? 'translate-x-0 bg-gray-900 items-center' : '-translate-x-full'
          } md:static md:translate-x-0 md:col-span-1 md:h-auto justify-center inline-flex z-40`}
        >
          <Sidebar activeTab={activeTab} onClick={onSideBarClick} />
        </div>
      </main>

      {/* Overlay to close the sidebar when clicked */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black opacity-50 z-30 md:hidden" 
          onClick={toggleSidebar}
        />
      )}
    </div>
  );
}

export default App;
