import React from 'react';

const WorkHistory = () => {
    const projects = [
        {
            title: "Senior Software Developer",
            company: "Paxcom(PaymentUs)",
            duration: "June 2020 - Present",
            description: "Automate the process to parse, transform, ingest & acknowledge data present on huge files provided to us via sftp servers.",
            role: "My role was to setup and integrate Apache Kafka for data streaming, enhancing data processing efficiency and reducing latency",
            tools: ["Node JS", "MongoDB", "Oracle", "AWS S3(Cloud Storage)", "Apache Kafka", "Kubernetes", "Terraform"]
        },
        {
            title: "Techinical Architect",
            company: "Integolf(FreeLance)",
            duration: "March 2023 - Sept 2024",
            description: "Provider of golfers and dedicated technologists intended to enhance every aspect of your golfing experience. The company's tee time booking software and golf tournament management platform have been meticulously crafted to seamlessly blend the love for the game with cutting-edge technology, ensuring courses give golfers the best deal possible right at their fingertips.",
            role: "My role was to discuss & understand the requirements and implement the optimal solution for the requirements.",
            tools: ["Node JS", "MongoDB", "AWS S3(Cloud Storage)", "HTML5", "CSS3", "ReactJS", "Redux", "AWS EC2", "Bitbucket pipelines", "pm2" ],
            links: ["https://www.integolf.com/", "https://courses.integolf.com/"]
        },
        {
            title: "Hazardous Waste Disposition",
            company: "Sphera Infosolutions",
            duration: "June 2018 - Feb 2019",
            description: "The project is a cloud-based web application designed to support the Defense Logistics Agency (DSA) Disposition Services environmental and acquisition personnel around the world to develop, solicit, award and administer high quality contrast solutions for hazardous waste disposal and the services to the war fighter.",
            role: "The project was shifting from Java servelets to ReactJS. My role was to implement the legacy screens and enhance the experiance.",
            tools: ["ReactJS", "Redux", "Redux Saga (Middleware)", "HTML5", "CSS3" ],
        }
    ];
    
    return (
        <div className="w-full xs:w-2/3 mx-auto ">
          <div className="space-y-6">
            {projects.map((project, index) => (
              <div key={index} className="border-l-4 border-blue-500 pl-4">
                <h2 className="text-xl font-semibold">
                  {project.title} at {project.company}
                </h2>
                <p className="text-sm text-gray-500">{project.duration}</p>
                <p className="mt-2">{project.description}</p>
                <p className="mt-2">Role: {project.role}</p>
                <div className="mt-2">
                  <span className="font-semibold">Tools Used: </span>
                  {project.tools.map((tool, i) => (
                    <span key={i} className="text-sm text-blue-600">
                      {tool}{i !== project.tools.length - 1 && ", "}
                    </span>
                  ))}
                </div>
                {
                    (project.links && project.links.length) && <div>
                        Links: {project.links.map(linksrc => <><a className="text-sm text-blue-600" href={linksrc} target='_blank'>{linksrc}</a> &nbsp;</>)}
                    </div>
                }
              </div>
            ))}
          </div>
        </div>
    );
};

export default WorkHistory;